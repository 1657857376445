<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <b-button
            v-if="can_list"
            class="btn-import mr-1"
            variant="danger"
            @click="handleNewPop"
        >
          {{ $t('position.positionList') }}
        </b-button>
        <DxTreeList
            v-if="can_list"
            id="employees"
            :data-source="employees"
            :root-value="-1"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :word-wrap-enabled="true"
            :expanded-row-keys="expandedRows"
            key-expr="id"
            parent-id-expr="chief_position_id"
        >
          <DxColumn data-field="name" :caption="this.$t('position.name')"/>
          <DxColumn
              data-field="description"
              :caption="this.$t('position.description')"
          />
          <DxRowDragging
              :on-drag-change="onDragChange"
              :on-reorder="onReorder"
              :allow-drop-inside-item="true"
              :allow-reordering="true"
              :show-drag-icons="true"
          />
        </DxTreeList>
        <h1 v-else>{{ $t('noPermission') }}</h1>
        <DxPopup
            :visible="positionPop"
            :close-on-outside-click="false"
            :show-close-button="false"
            :title="$t('position.newTitle')"
        >
          <DxToolbarItem
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="closeAddingPopupButton"
          />
          <div
              v-if="false"
              class="spinner-border m-auto"
              role="status"
          >
            <span class="sr-only">Loading... </span>
          </div>

          <DxScrollView v-else
                        width="100%"
                        height="100%"
          >
            <DxDataGrid
                v-if="can_list"
                id="site-sub-contractor-user-table"
                ref="data-grid"
                width="100%"
                :show-borders="true"
                :data-source="dataPositions"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :hover-state-enabled="true"
                key-expr="id"
                exporting="exportGrid"
                @row-inserted="onInserted"
                @row-updated="onUpdated"
                @row-removed="onRemoved"
                @exporting="onExporting"
                @edit-canceling="true"
                @edit-canceled="true"
                @init-new-row="userEditClick"
            >
              <DxFilterRow :visible="true"/>
              <DxSearchPanel :visible="true"/>
              <DxColumnFixing :enabled="true"/>
              <DxColumnChooser :enabled="true"/>
              <DxScrolling mode="standard"/>
              <DxGrouping :context-menu-enabled="true"/>
              <DxGroupPanel :visible="true"/>
              <DxPaging :page-size="20"/>

              <DxPager
                  :visible="true"
                  :allowed-page-sizes="pageSizes"
                  :display-mode="displayMode"
                  :show-page-size-selector="showPageSizeSelector"
                  :show-info="showInfo"
                  :show-navigation-buttons="showNavButtons"
              />
              <DxExport
                  :enabled="true"
                  :visible="true"
                  :allow-export-selected-data="false"
              />
              <DxEditing
                  :allow-updating="can_edit"
                  :allow-deleting="can_delete"
                  :allow-adding="can_create"
                  mode="row"
                  :select-text-on-edit-start="true"
                  :use-icons="true"
              />
              <DxColumn data-field="id" caption="ID" :allow-editing="false"/>
              <DxColumn
                  data-field="name"
                  :name="this.$t('position.name')"
                  :caption="this.$t('position.name')"
              />
              <DxColumn
                  data-field="description"
                  :name="this.$t('position.description')"
                  :caption="this.$t('position.description')"
              />
              <DxColumn
                  data-field="chief_position_id"
                  :name="this.$t('position.chief_position_id')"
                  :caption="this.$t('position.chief_position_id')"
              >
                <DxLookup
                    :data-source="dataPositions"
                    value-expr="id"
                    display-expr="name"
                />
              </DxColumn>
            </DxDataGrid>
          </DxScrollView>


        </DxPopup>
        <b-button
            v-if="readOnly && can_list"
            class="btn-import mr-1"
            variant="danger"
            @click="saveFn"
        >{{ $t('buttons.save') }}
        </b-button>
      </div>
    </div>
    <b-modal
        ref="import-modal"
        centered
        title="Import"
        ok-only
        :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0"/>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios';
import {BCard, BModal, BCardText, BSpinner, BButton} from 'bootstrap-vue';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {DxTreeList, DxColumn, DxRowDragging} from 'devextreme-vue/tree-list';
import {
  DxDataGrid,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport,
  DxLookup,
  DxPager
} from 'devextreme-vue/data-grid';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';

import 'jspdf-autotable';
import {readOnly} from "@/auth/utils";
import {DxScrollView} from 'devextreme-vue/scroll-view';

export default {
  components: {
    DxScrollView,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxSearchPanel,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxExport,
    DxLookup,
    DxPager,
    BCard,
    DxPopup,
    DxToolbarItem,
    BButton,
    BCardText,
    BModal,
    BSpinner,
    BCard,
    DxTreeList,
    DxColumn,
    DxRowDragging
  },
  data() {
    return {
      displayMode: 'full',
      positionPop: false,
      employees: [],
      rowData: {
        name: '',
        master: false,
        chief_position_id: null
      },
      expandedRows: [],
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataUnit: [],
      dataCurrency: [],
      databoq: [],
      languages: [],
      dataPositions: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      file: null,
      importResult: 'deneme',
      readOnly: false,
      closeAddingPopupButton: {
        text: 'Close',
        onClick: () => {
          this.positionPop = false;
        }
      }
    };
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getDataPositions();
    await this.getUnit();
  },

  methods: {
    async createPositionPop() {
      try {
        const result = await axios.post(`/create-position`, {
          name: this.rowData.name,
          master: this.rowData.master,
          chief_position_id: this.rowData.chief_position_id
        });
        this.closeAddingPop();
        if (result.status === 200) {
          this.showToast({
            variant: 'success',
            text: 'Pozisyon başarıyla eklendi'
          });
        } else {
          this.showToast({
            variant: 'danger',
            text: 'Pozisyon eklenemedi'
          });
        }
        await this.getUnit();
      } catch (e) {
        this.showToast({
          variant: 'danger',
          text: 'Pozisyon eklenemedi'
        });
      }
    },
    closeAddingPop() {
      this.positionPop = false;
      this.rowData = {
        name: '',
        master: false,
        chief_position_id: null
      };
    },
    handleNewPop() {
      this.positionPop = true;
    },
    onInserted(e) {
      const {name, chief_position_id, description} = e.data;
      axios
          .post('/create-position', {
            name,
            chief_position_id,
            description
          })
          .then(async (response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla eklendi.'
            });
            await this.getDataPositions();
            await this.getUnit();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onRemoved(e) {
      axios
          .delete(`delete-position/${e.data.id}`, {})
          .then(async (response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla silindi.'
            });
            await this.getDataPositions();
            await this.getUnit();
          })
          .catch(async (err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
            await this.getDataPositions();
            await this.getUnit();
          });
    },
    onUpdated(e) {
      const {id, name, chief_position_id, description} = e.data;
      axios
          .post(`/edit-position/${id}`, {
            name,
            chief_position_id,
            description
          })
          .then(async (response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla güncellendi.'
            });
            await this.getDataPositions();
            await this.getUnit();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
      // kapo
    },
    onExporting() {
    },
    userEditClick() {
    },
    async saveFn() {
      try {
        const result = await axios.post('/re-organize', {
          positions: this.employees
        });
        if (result.status === 200) {
          this.showToast({
            variant: 'success',
            title: 'Şema güncellendi',
            text: 'Şema güncellendi'
          });
          await this.getUnit();
        }
      } catch (e) {
        this.showToast({
          variant: 'danger',
          title: 'Hata',
          text: 'Şema güncellenemedi'
        });
      }
    },
    onDragChange(e) {
      const visibleRows = e.component.getVisibleRows();
      const sourceNode = e.component.getNodeByKey(e.itemData.id);
      let targetNode = visibleRows[e.toIndex].node;

      while (targetNode && targetNode.data) {
        if (targetNode.data.id === sourceNode.data.id) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();

      if (e.dropInsideItem) {
        e.itemData.chief_position_id = visibleRows[e.toIndex].key;

        e.component.refresh();
      } else {
        const employeeList = this.employees.slice();
        const sourceData = e.itemData;
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

        if (targetData && e.component.isRowExpanded(targetData.id)) {
          sourceData.chief_position_id = targetData.id;
          targetData = null;
        } else {
          sourceData.chief_position_id = targetData
              ? targetData.chief_position_id
              : -1;
        }

        const sourceIndex = this.employees.indexOf(sourceData);
        employeeList.splice(sourceIndex, 1);
        employeeList.splice(sourceIndex, 1);

        const targetIndex = this.employees.indexOf(targetData) + 1;
        employeeList.splice(targetIndex, 0, sourceData);

        this.employees = employeeList;
      }
    },
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Organization Chart'
      });
      this.can_edit = this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly && pageAuths.data.can_delete;
      this.can_create = this.readOnly && pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getDataPositions() {
      this.dataPositions = (await axios.get('/list-position')).data;
      if (this.dataPositions.length > 0) {
        this.dataPositions.forEach((a) => {
          if (a.chief_position_id === -1) {
            a.chief_position_id = null;
          }
        });
      }
    },
    async getUnit() {
      this.error = '';
      try {
        this.employees = (await axios.get('/list-position')).data || [];
        this.employees.forEach((e) => {
          this.expandedRows.push(e.id);
        });
        this.dataLoading = false;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async refreshList() {
      this.dataLoading = true;
      await this.getLanguage();
      await this.getDataPositions();
      await this.getUnit();
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    handleImport() {
      if (!this.file) {
        this.showToast({
          variant: 'danger',
          title: this.$t('modal.error'),
          text: this.$t('modal.please-choose-file-first')
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.importResult = '';
      this.$refs['import-modal'].show();
      this.showToast({
        variant: 'info',
        icon: 'InfoIcon',
        text: this.$t('modal.start-import')
      });
      axios
          .post(`import-boq`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.importResult = this.$t('general.importSuccess');
              this.showToast({
                variant: 'success',
                text: this.importResult
              });
              this.refreshList();
            } else {
              this.importResult = this.$t('general.importError');
              this.showToast({
                variant: 'danger',
                text: this.importResult
              });
            }
          })
          .catch((err) => {
            this.importResult = err?.response?.data || err?.message;
            this.showToast({
              variant: 'danger',
              text: this.importResult
            });
          });
    }
  }
};
</script>


<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: bisque !important;
}

.btn-danger {
  margin-top: 20px !important;
  display: block;
  margin-left: auto !important;
  margin-right: 0px !important;
}
</style>
